<template>
  <div class="venueOverview">
    <!-- 图表数据 -->
    <div class="content">
      <div class="item content-l">
        <div class="venue-count" id="venueCountEchart"></div>
        <div class="venue-type">
          <div
            :class="{ active: item.select }"
            v-for="(item, index) in listData"
            :key="index"
            @click="handelSelect(item, index)"
          >
            {{ item.select ? "" : item.name }}
          </div>
        </div>
      </div>
      <div class="item content-c">
        <div class="commont content-c-l">
          <div id="jobLeftEchart"></div>
          <span class="txt">志愿者</span>
        </div>
        <div class="commont content-c-c">
          <div id="jobCenterEchart"></div>
          <span class="txt">护理员</span>
        </div>
        <div class="commont content-c-r">
          <div id="jobRightEchart"></div>
          <span class="txt">医生</span>
        </div>
      </div>
      <div class="item content-r">
        <el-table :data="tableList" :height="'100%'" class="item-table">
          <el-table-column
            label="排名"
            align="center"
            type="index"
          ></el-table-column>
          <el-table-column prop="name" label="场馆" align="center">
          </el-table-column>
          <el-table-column
            width="150"
            prop="number"
            label="排行"
            align="center"
            class="ranking"
          >
            <template slot-scope="{ row }">
              <span v-for="item in 5" :key="item">
                <img
                  v-if="item <= row.number"
                  style="width: 20px; height: 20px"
                  src="./img/start.png"
                  alt=""
                />
                <img
                  style="width: 20px; height: 20px"
                  v-else
                  src="./img/gray-start.png"
                  alt=""
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="educationalMap">
      <iframe
        width="100%"
        height="100%"
        src="https://gxq.zhuneng.cn/d3m/#/s/52c014c509cf4d9d897c761049dbc77d"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { drawVenuePieChart, drawJobPieChart } from "@/utils/echart";
import { debounce } from "@/utils/utils";
export default {
  name: "venueOverview",
  components: {},
  props: {},
  data() {
    return {
      listData: [
        { value: 2, name: "体育运动", select: false, color: "#5d7bd4" },
        { value: 3, name: "健身游泳", select: false, color: "#93cb7d" },
      ],
      jobListData: [
        {
          name: "志愿者",
          value: 8,
        },
        {
          name: "志愿者1",
          value: 6,
        },
        {
          name: "志愿者2",
          value: 8,
        },
        {
          name: "志愿者3",
          value: 6,
        },
      ],
      tableList: [
        {
          name: "党建服务中心养老中心",
          number: 5,
        },
        {
          name: "杜特体育运动馆",
          number: 4,
        },
        {
          name: "绿园-游泳馆",
          number: 2,
        },
      ],

      venueCountPie: "", // 场馆数量
      jobLeftPie: "",
      jobCenterPie: "",
      jobRightPie: "",
      option: {
        series: [
          {
            data: [],
          },
        ],
      },
      venueEchart: "",
      isSelected: null,
      colors: ["#5d7bd4", "#93cb7d"],
    };
  },
  created() {},
  computed: {
    totalValue() {
      return this.listData.reduce((pre, cur) => {
        return (pre = pre + (cur.value ? cur.value : 0));
      }, 0);
    },
  },
  watch: {
    listData: {
      handler(newVlaue) {
        this.isSelected = this.listData.every((item) => item.select);
        const isAllSelected = this.listData.every((item) => !item.select);
        if (this.isSelected) {
          this.venueEchart.setOption(this.option);
        }
        if (isAllSelected) {
          drawVenuePieChart(
            "venueCountEchart",
            this.listData,
            this.totalValue,
            this.colors
          );
        }
      },
      deep: true,
    },
  },
  mounted() {
    let _this = this;
    // 场馆数量图表
    this.venueEchart = drawVenuePieChart(
      "venueCountEchart",
      this.listData,
      this.totalValue,
      this.colors
    );
    this.venueCountPie = debounce(() => {
      drawVenuePieChart(
        "venueCountEchart",
        this.listData,
        this.totalValue,
        this.colors
      ).resize();
    }, 100);
    // ---start职业人员数量图表
    drawJobPieChart("jobLeftEchart", this.jobListData, 27);
    this.jobLeftPie = debounce(() => {
      drawJobPieChart("jobLeftEchart", this.jobListData, 27).resize();
    }, 100);
    drawJobPieChart("jobCenterEchart", this.jobListData, 20);
    this.jobCenterPie = debounce(() => {
      drawJobPieChart("jobCenterEchart", this.jobListData, 20).resize();
    }, 100);
    drawJobPieChart("jobRightEchart", this.jobListData, 1);

    this.jobRightPie = debounce(() => {
      drawJobPieChart("jobRightEchart", this.jobListData, 1).resize();
    }, 100);
    // ---end 职业人员数量图表
    window.addEventListener("resize", _this.venueCountPie, true);
    window.addEventListener("resize", _this.jobLeftPie, true);
    window.addEventListener("resize", _this.jobCenterPie, true);
    window.addEventListener("resize", _this.jobRightPie, true);
  },

  methods: {
    handelSelect(item, index) {
      let filterList, totalValue, colors;
      if (this.isSelected) {
        filterList = this.listData.filter((res) => res.name == item.name);
        colors = filterList.map((item) => item.color);
        totalValue = filterList.reduce((pre, cur) => {
          return (pre = pre + (cur.value ? cur.value : 0));
        }, 0);
      } else {
        filterList = this.listData.filter((res) => res.name !== item.name);
        colors = filterList.map((item) => item.color);
        totalValue = filterList.reduce((pre, cur) => {
          return (pre = pre + (cur.value ? cur.value : 0));
        }, 0);
      }
      drawVenuePieChart("venueCountEchart", filterList, totalValue, colors);
      item.select = !item.select;
    },
  },
  destroyed() {
    let _this = this;
    window.removeEventListener("resize", _this.venueCountPie);
    window.removeEventListener("resize", _this.jobLeftPie);
    window.removeEventListener("resize", _this.jobCenterPie);
    window.removeEventListener("resize", _this.jobRightPie);
  },
};
</script>

<style scoped lang="less">
.venueOverview {
  box-sizing: border-box;
  height: 100%;
  padding: 60px 20px 20px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .content {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 30%;
      height: 280px;
      background: #ffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      display: flex;
      @media screen and (max-width: 1920px) {
        height: 180px;
      }
    }
    .content-l {
      display: flex;
      align-items: center;
      .venue-type {
        width: 40%;
        padding: 0 30px 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        div {
          width: 100%;
          height: 40px;
          cursor: pointer;
          line-height: 40px;
          padding-right: 10px;
          text-align: end;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:first-child {
            background-color: #5d7bd4;
          }
          &:last-child {
            background-color: #93cb7d;
          }
        }
        .active {
          background-color: #cccccc !important;
        }
      }
      #venueCountEchart {
        width: 80%;
        height: 100%;
      }
    }
    .content-c {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .commont {
        flex: 1;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        > div {
          width: 100%;
          height: 100%;
        }
        .txt {
          font-size: 18px;
          position: absolute;
          left: 50%;
          bottom: 10%;
          transform: translateX(-50%);
          text-align: center;
          white-space: nowrap;
        }
      }
    }
    .content-r {
      box-sizing: border-box;
      padding: 20px;
      overflow: hidden;
      .item-table {
        &::before {
          width: 0;
          height: 0;
        }
      }
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px; // 横向滚动条
      height: 6px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #ddd;
      border-radius: 3px;
    }
  }
  .educationalMap {
    height: calc(100% - 280px);
    @media screen and (max-width: 1920px) {
      height: calc(100% - 180px);
    }
    margin-top: 20px;
  }
}
</style>
